// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dadggHrxZFpLcGPm008T{margin-bottom:16px}.pceKAizD9cnilnY9eeAc{margin-bottom:16px}`, "",{"version":3,"sources":["webpack://./components/GForm/GForm.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGF,sBACE,kBAAA","sourcesContent":[".collapse {\n  margin-bottom: 16px;\n}\n\n.label {\n  margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapse": `dadggHrxZFpLcGPm008T`,
	"label": `pceKAizD9cnilnY9eeAc`
};
export default ___CSS_LOADER_EXPORT___;

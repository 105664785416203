// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.srs09k6Fzjt5Gfo1IHgA table {
  width: 100%;
}

.fmAroEW_gvh1g5A4hUKQ {
  margin-top: 20px;
}

.ajs7OppUHWATvtDomW3Q {
  display: inline-flex;
}
`, "",{"version":3,"sources":["webpack://./components/GTable/GTable.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".root table {\n  width: 100%;\n}\n\n.pagination {\n  margin-top: 20px;\n}\n\n.checkbox {\n  display: inline-flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `srs09k6Fzjt5Gfo1IHgA`,
	"pagination": `fmAroEW_gvh1g5A4hUKQ`,
	"checkbox": `ajs7OppUHWATvtDomW3Q`
};
export default ___CSS_LOADER_EXPORT___;

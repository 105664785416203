// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.configure-plugin {
  margin-top: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

/* Spinner */

.spin {
  width: 100%;
  margin-top: 200px;
  margin-bottom: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spin-text {
  margin-top: 20px;
}

/* Tables */

.disabled-row {
  background: #f0f0f0;
}

.highlighted-row {
  background: var(--highlighted-row-bg);
}

/* Navigation */

.navbarRootFallback {
  margin-top: 24px;
}

.page-title {
  margin-bottom: 16px;
}

td.rc-table-cell {
  height: 44px !important;

  /* works better than break-all, especially for table headers */
  word-break: break-word;
}

.grecaptcha-badge {
  visibility: hidden;
}
`, "",{"version":3,"sources":["webpack://./assets/style/global.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA,YAAY;;AAEZ;EACE,WAAW;EACX,iBAAiB;EACjB,oBAAoB;EACpB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA,WAAW;;AAEX;EACE,mBAAmB;AACrB;;AAEA;EACE,qCAAqC;AACvC;;AAEA,eAAe;;AAEf;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;;EAEvB,8DAA8D;EAC9D,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".configure-plugin {\n  margin-top: 10px;\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n}\n\n/* Spinner */\n\n.spin {\n  width: 100%;\n  margin-top: 200px;\n  margin-bottom: 200px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.spin-text {\n  margin-top: 20px;\n}\n\n/* Tables */\n\n.disabled-row {\n  background: #f0f0f0;\n}\n\n.highlighted-row {\n  background: var(--highlighted-row-bg);\n}\n\n/* Navigation */\n\n.navbarRootFallback {\n  margin-top: 24px;\n}\n\n.page-title {\n  margin-bottom: 16px;\n}\n\ntd.rc-table-cell {\n  height: 44px !important;\n\n  /* works better than break-all, especially for table headers */\n  word-break: break-word;\n}\n\n.grecaptcha-badge {\n  visibility: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lppJ8152Wv1l9NtT7Qs7 {
  display: block;
}

.mxckCE8EYMU8bOrmi3rw {
  margin: 16px 0 0 16px;
}

.A_pPhmIMMkKKMtTtKmrj {
  margin: 4px 4px 50px 4px;
}
`, "",{"version":3,"sources":["webpack://./containers/ScheduleForm/ScheduleForm.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".root {\n  display: block;\n}\n\n.title {\n  margin: 16px 0 0 16px;\n}\n\n.content {\n  margin: 4px 4px 50px 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `lppJ8152Wv1l9NtT7Qs7`,
	"title": `mxckCE8EYMU8bOrmi3rw`,
	"content": `A_pPhmIMMkKKMtTtKmrj`
};
export default ___CSS_LOADER_EXPORT___;

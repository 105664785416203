// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hCYj7XRxbhy7mmzNqZsQ {
  width: 100%;
}

.hCYj7XRxbhy7mmzNqZsQ table {
  width: 100%;
}

.hCYj7XRxbhy7mmzNqZsQ table tbody tr.gq99QTBM8p5A0Jih4yMh {
  background: var(--background-secondary);
}

.hCYj7XRxbhy7mmzNqZsQ tr {
  min-height: 56px;
}

.hCYj7XRxbhy7mmzNqZsQ tr:hover {
  /* background: var(--secondary-background); */
}

.hCYj7XRxbhy7mmzNqZsQ th:first-child {
  padding-left: 20px;
}

.hCYj7XRxbhy7mmzNqZsQ td {
  min-height: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.NEm7SycBa8NmA6UzOcgS {
  width: 100%;
  margin-top: 20px;
}

.Lw4GBtXfz5JkV03LOoUM {
  padding: 10px;
  color: var(--primary-text-color);
  pointer-events: none;
  transform: rotate(-90deg);
  transform-origin: center;
  transition: transform 0.2s;
}

/* to allow expand on expand-button click */
.hCYj7XRxbhy7mmzNqZsQ table .rc-table-row-expand-icon-cell > span {
  pointer-events: none;
}

.swTFiM1fnq2884qsLYE1 {
  transform: rotate(0deg);
}
`, "",{"version":3,"sources":["webpack://./components/Table/Table.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,gCAAgC;EAChC,oBAAoB;EACpB,yBAAyB;EACzB,wBAAwB;EACxB,0BAA0B;AAC5B;;AAEA,2CAA2C;AAC3C;EACE,oBAAoB;AACtB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".root {\n  width: 100%;\n}\n\n.root table {\n  width: 100%;\n}\n\n.root table tbody tr.row-even {\n  background: var(--background-secondary);\n}\n\n.root tr {\n  min-height: 56px;\n}\n\n.root tr:hover {\n  /* background: var(--secondary-background); */\n}\n\n.root th:first-child {\n  padding-left: 20px;\n}\n\n.root td {\n  min-height: 60px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n}\n\n.pagination {\n  width: 100%;\n  margin-top: 20px;\n}\n\n.expand-icon {\n  padding: 10px;\n  color: var(--primary-text-color);\n  pointer-events: none;\n  transform: rotate(-90deg);\n  transform-origin: center;\n  transition: transform 0.2s;\n}\n\n/* to allow expand on expand-button click */\n.root table :global(.rc-table-row-expand-icon-cell) > span {\n  pointer-events: none;\n}\n\n.expand-icon__expanded {\n  transform: rotate(0deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `hCYj7XRxbhy7mmzNqZsQ`,
	"row-even": `gq99QTBM8p5A0Jih4yMh`,
	"pagination": `NEm7SycBa8NmA6UzOcgS`,
	"expand-icon": `Lw4GBtXfz5JkV03LOoUM`,
	"expand-icon__expanded": `swTFiM1fnq2884qsLYE1`
};
export default ___CSS_LOADER_EXPORT___;

export enum ActionKey {
  ADD_NEW_COLUMN_TO_ALERT_GROUP = 'ADD_NEW_COLUMN_TO_ALERT_GROUP',
  REMOVE_COLUMN_FROM_ALERT_GROUP = 'REMOVE_COLUMN_FROM_ALERT_GROUP',
  RESET_COLUMNS_FROM_ALERT_GROUP = 'RESET_COLUMNS_FROM_ALERT_GROUP',
  UPDATE_PERSONAL_EVENTS = 'UPDATE_PERSONAL_EVENTS',
  UPDATE_ALERT_GROUP = 'UPDATE_ALERT_GROUP',
  FETCH_INCIDENTS = 'FETCH_INCIDENTS',
  FETCH_INCIDENTS_POLLING = 'FETCH_INCIDENTS_POLLING',
  FETCH_INCIDENTS_AND_STATS = 'FETCH_INCIDENTS_AND_STATS',
  UPDATE_FILTERS_AND_FETCH_INCIDENTS = 'UPDATE_FILTERS_AND_FETCH_INCIDENTS',
  FETCH_INTEGRATIONS = 'FETCH_INTEGRATIONS',
}

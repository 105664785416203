// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D1BoTPk3n_UjonMPj6hq {
  height: 88px;
  position: relative;
  cursor: pointer;
}

.HNseezrRPb6Q_C1AZBCQ {
  position: absolute;
  left: 20px;
  top: 20px;
  color: var(--disabled-text-color);
}

.ltsAgjGBqqRRVQkRJAGH {
  position: absolute;
  top: 14px;
  left: 90px;
}

.kOVlq30YnJUNlKKtI8Eg::before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-image: linear-gradient(270deg, #f55f3e 0%, #f83 100%);
}
`, "",{"version":3,"sources":["webpack://./components/CardButton/CardButton.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,iCAAiC;AACnC;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,WAAW;EACX,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,SAAS;EACT,UAAU;EACV,gEAAgE;AAClE","sourcesContent":[".root {\n  height: 88px;\n  position: relative;\n  cursor: pointer;\n}\n\n.icon {\n  position: absolute;\n  left: 20px;\n  top: 20px;\n  color: var(--disabled-text-color);\n}\n\n.meta {\n  position: absolute;\n  top: 14px;\n  left: 90px;\n}\n\n.root_selected::before {\n  display: block;\n  content: '';\n  position: absolute;\n  left: 0;\n  top: 0;\n  bottom: 0;\n  width: 4px;\n  background-image: linear-gradient(270deg, #f55f3e 0%, #f83 100%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `D1BoTPk3n_UjonMPj6hq`,
	"icon": `HNseezrRPb6Q_C1AZBCQ`,
	"meta": `ltsAgjGBqqRRVQkRJAGH`,
	"root_selected": `kOVlq30YnJUNlKKtI8Eg`
};
export default ___CSS_LOADER_EXPORT___;

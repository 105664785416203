// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XNQkAwgqijvRw5kMIaYZ {
  color: var(--primary-text-link);
}

.q6hpttyH3jUGuDGcL6l0 {
  color: var(--disabled-text-color);
}

.Lur9edfPvf8mHgHjDC4U {
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./components/PluginLink/PluginLink.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".root {\n  color: var(--primary-text-link);\n}\n\n.root_disabled {\n  color: var(--disabled-text-color);\n}\n\n.no-wrap {\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `XNQkAwgqijvRw5kMIaYZ`,
	"root_disabled": `q6hpttyH3jUGuDGcL6l0`,
	"no-wrap": `Lur9edfPvf8mHgHjDC4U`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MkBMBbv0tIikfD6TtYng{border:var(--border);width:100%}.AHK0QJeQr1iRpqPlbQ55{padding:8px;cursor:pointer;display:flex;align-items:center}.BDdUhmlBqG7Ka_ML4cDc{background:var(--secondary-background)}.poTYRF5i81plFq1IdH49{display:block;margin-left:8px;flex-grow:1}.kTgXN22J5qweojAxZoE5{padding:16px}.pkWj1SSkmOGvDkIjlmQj{color:var(--secondary-text-color);transform-origin:center;transition:transform .2s}.ndP9gSXgad1KOFEHM0qc{transform:rotate(90deg)}`, "",{"version":3,"sources":["webpack://./components/Collapse/Collapse.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CACA,UAAA,CAGF,sBACE,WAAA,CACA,cAAA,CACA,YAAA,CACA,kBAAA,CAGF,sBACE,sCAAA,CAGF,sBACE,aAAA,CACA,eAAA,CACA,WAAA,CAGF,sBACE,YAAA,CAGF,sBACE,iCAAA,CACA,uBAAA,CACA,wBAAA,CAEA,sBACE,uBAAA","sourcesContent":[".root {\n  border: var(--border);\n  width: 100%;\n}\n\n.header {\n  padding: 8px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n\n.header_with-background {\n  background: var(--secondary-background);\n}\n\n.label {\n  display: block;\n  margin-left: 8px;\n  flex-grow: 1;\n}\n\n.content {\n  padding: 16px;\n}\n\n.icon {\n  color: var(--secondary-text-color);\n  transform-origin: center;\n  transition: transform 0.2s;\n\n  &--rotated {\n    transform: rotate(90deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `MkBMBbv0tIikfD6TtYng`,
	"header": `AHK0QJeQr1iRpqPlbQ55`,
	"header_with-background": `BDdUhmlBqG7Ka_ML4cDc`,
	"label": `poTYRF5i81plFq1IdH49`,
	"content": `kTgXN22J5qweojAxZoE5`,
	"icon": `pkWj1SSkmOGvDkIjlmQj`,
	"icon--rotated": `ndP9gSXgad1KOFEHM0qc`
};
export default ___CSS_LOADER_EXPORT___;

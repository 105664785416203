// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PAGiYEwOJpPvvROOBR5w {
  border-radius: 2px;
  line-height: 100%;
  padding: 5px 8px;
  color: white;
  display: inline-block;
  white-space: nowrap;
}

.Z_TRyHVxF2X4Mvk0I2km {
  font-size: 12px;
  height: 24px;
}
`, "",{"version":3,"sources":["webpack://./components/Tag/Tag.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,YAAY;AACd","sourcesContent":[".root {\n  border-radius: 2px;\n  line-height: 100%;\n  padding: 5px 8px;\n  color: white;\n  display: inline-block;\n  white-space: nowrap;\n}\n\n.size-small {\n  font-size: 12px;\n  height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `PAGiYEwOJpPvvROOBR5w`,
	"size-small": `Z_TRyHVxF2X4Mvk0I2km`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.F3zxdDNkFmLXxLg0fDeA,.mTvYGbkrqUqIJpqi8dE0{padding:16px}.F3zxdDNkFmLXxLg0fDeA{background-color:var(--background-secondary);border:var(--border-medium) !important;border-radius:4px !important}.MFS6gf0UAcPvZ1Md5P8u{border-bottom-left-radius:0px !important;border-bottom-right-radius:0px !important}.mTvYGbkrqUqIJpqi8dE0{background:var(--background-primary);border:var(--border-medium) !important;border-top:none !important;border-bottom-left-radius:4px !important;border-bottom-right-radius:4px !important}`, "",{"version":3,"sources":["webpack://./components/Integrations/IntegrationBlock.module.scss"],"names":[],"mappings":"AAAA,4CAEE,YAAA,CAGF,sBACE,4CAAA,CACA,sCAAA,CACA,4BAAA,CAEA,sBACE,wCAAA,CACA,yCAAA,CAIJ,sBACE,oCAAA,CACA,sCAAA,CAEA,0BAAA,CACA,wCAAA,CACA,yCAAA","sourcesContent":[".integrationBlock__heading,\n.integrationBlock__content {\n  padding: 16px;\n}\n\n.integrationBlock__heading {\n  background-color: var(--background-secondary);\n  border: var(--border-medium) !important;\n  border-radius: 4px !important;\n\n  &--noBorderBottom {\n    border-bottom-left-radius: 0px !important;\n    border-bottom-right-radius: 0px !important;\n  }\n}\n\n.integrationBlock__content {\n  background: var(--background-primary);\n  border: var(--border-medium) !important;\n  \n  border-top: none !important;\n  border-bottom-left-radius: 4px !important;\n  border-bottom-right-radius: 4px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"integrationBlock__heading": `F3zxdDNkFmLXxLg0fDeA`,
	"integrationBlock__content": `mTvYGbkrqUqIJpqi8dE0`,
	"integrationBlock__heading--noBorderBottom": `MFS6gf0UAcPvZ1Md5P8u`
};
export default ___CSS_LOADER_EXPORT___;

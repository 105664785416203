// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.x1HBWy1kgvNwDSXvBqWQ {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
}

.UduL1v40NTdWqmoH07Tk {
  width: 12px;
  height: 12px;
}

._YQzEiUgtPrOKr4I4l0s {
  width: 16px;
  height: 16px;
}

.NrhZT3ekMBhn8Po7IQ5M {
  width: 24px;
  height: 24px;
}

.TvDMHjZXgaH62RnjkJ9M {
  width: 32px;
  height: 32px;
}
`, "",{"version":3,"sources":["webpack://./components/Avatar/Avatar.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".root {\n  display: inline-block;\n  border-radius: 50%;\n  overflow: hidden;\n}\n\n.avatarSize-xs {\n  width: 12px;\n  height: 12px;\n}\n\n.avatarSize-small {\n  width: 16px;\n  height: 16px;\n}\n\n.avatarSize-medium {\n  width: 24px;\n  height: 24px;\n}\n\n.avatarSize-large {\n  width: 32px;\n  height: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `x1HBWy1kgvNwDSXvBqWQ`,
	"avatarSize-xs": `UduL1v40NTdWqmoH07Tk`,
	"avatarSize-small": `_YQzEiUgtPrOKr4I4l0s`,
	"avatarSize-medium": `NrhZT3ekMBhn8Po7IQ5M`,
	"avatarSize-large": `TvDMHjZXgaH62RnjkJ9M`
};
export default ___CSS_LOADER_EXPORT___;

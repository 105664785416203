// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XZXI0SX7xmOBT5e05Ppv {
  width: 100%;
}

.zA_rgDqVp22gpSs3qOuF {
  z-index: 1062;
  box-shadow: var(--focused-box-shadow);
  background: var(--hover-selected-hardcoded) !important;
}

.Mqob3G79sUnnSlDLjySm {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin: 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ejJcZ46xki5FBdI2huk8 {
  cursor: pointer;
  margin-top: 12px;
}

.GUyrOGx9PgscjvSo5SG4 {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.Tru9GwsvkUhVILm3nJYH {
  background: #22252b;
  border-radius: 2px;
  display: flex;
  position: relative;
}

.JrmOVZUP7ImpZaNgb6pP {
  position: absolute;
  top: 8px;
  right: 5px;
}

.Tru9GwsvkUhVILm3nJYH:hover {
  background: var(--hover-selected-hardcoded);
}

.UtlkqCW17FcVC9HTIawN {
  display: block;
  color: var(--always-gray);
}

.UtlkqCW17FcVC9HTIawN:hover {
  color: white;
}

.Tru9GwsvkUhVILm3nJYH:hover .ESEKy_2yMil319UBzPvL {
  display: block;
}

.Frr21r8QOfSGzS2fTkdb {
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--secondary-text-color);
  cursor: pointer;
}

.wJYhmCeQ_7nJcnCqW0m8 {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./components/UserGroups/UserGroups.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,sDAAsD;AACxD;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,UAAU;EACV,SAAS;EACT,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;AACZ;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kCAAkC;EAClC,eAAe;AACjB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".root {\n  width: 100%;\n}\n\n.sortable-helper {\n  z-index: 1062;\n  box-shadow: var(--focused-box-shadow);\n  background: var(--hover-selected-hardcoded) !important;\n}\n\n.separator {\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 16px;\n  text-align: center;\n  margin: 4px 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.separator__clickable {\n  cursor: pointer;\n  margin-top: 12px;\n}\n\n.groups {\n  width: 100%;\n  padding: 0;\n  margin: 0;\n  list-style: none;\n  display: flex;\n  flex-direction: column;\n  gap: 1px;\n}\n\n.user {\n  background: #22252b;\n  border-radius: 2px;\n  display: flex;\n  position: relative;\n}\n\n.user-buttons {\n  position: absolute;\n  top: 8px;\n  right: 5px;\n}\n\n.user:hover {\n  background: var(--hover-selected-hardcoded);\n}\n\n.icon {\n  display: block;\n  color: var(--always-gray);\n}\n\n.icon:hover {\n  color: white;\n}\n\n.user:hover .delete-icon {\n  display: block;\n}\n\n.add-user-group {\n  width: 100%;\n  text-align: center;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 16px;\n  color: var(--secondary-text-color);\n  cursor: pointer;\n}\n\n.select {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `XZXI0SX7xmOBT5e05Ppv`,
	"sortable-helper": `zA_rgDqVp22gpSs3qOuF`,
	"separator": `Mqob3G79sUnnSlDLjySm`,
	"separator__clickable": `ejJcZ46xki5FBdI2huk8`,
	"groups": `GUyrOGx9PgscjvSo5SG4`,
	"user": `Tru9GwsvkUhVILm3nJYH`,
	"user-buttons": `JrmOVZUP7ImpZaNgb6pP`,
	"icon": `UtlkqCW17FcVC9HTIawN`,
	"delete-icon": `ESEKy_2yMil319UBzPvL`,
	"add-user-group": `Frr21r8QOfSGzS2fTkdb`,
	"select": `wJYhmCeQ_7nJcnCqW0m8`
};
export default ___CSS_LOADER_EXPORT___;

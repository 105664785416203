// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prPuVrJ34_WOTe4KV6iF {
  white-space: nowrap;
}

.QT5nvcrxBjWSQsL52GSk {
  padding-right: 6px;
}

.M3VYD8bysrjieleV7g_D {
  max-width: none !important;
}

.gfMG9Wxs5P7Go1iyWJb9 {
  margin-right: 10px !important;
}

.lDxMTi3787ZeQ25Y2BQ8 {
  cursor: move;
}

.qhxwbC2mbhgHq2szVW9S {
  cursor: not-allowed;
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./components/Policy/Policy.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[".note {\n  white-space: nowrap;\n}\n\n.note-icon {\n  padding-right: 6px;\n}\n\n.tooltip {\n  max-width: none !important;\n}\n\n.control {\n  margin-right: 10px !important;\n}\n\n.handle {\n  cursor: move;\n}\n\n.handle_disabled {\n  cursor: not-allowed;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"note": `prPuVrJ34_WOTe4KV6iF`,
	"note-icon": `QT5nvcrxBjWSQsL52GSk`,
	"tooltip": `M3VYD8bysrjieleV7g_D`,
	"control": `gfMG9Wxs5P7Go1iyWJb9`,
	"handle": `lDxMTi3787ZeQ25Y2BQ8`,
	"handle_disabled": `qhxwbC2mbhgHq2szVW9S`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.m7_uKyBfgPHZXPdeqCay{position:relative;width:100%;min-height:200px}.m7_uKyBfgPHZXPdeqCay:hover .aKm5zEOfOz0C_VfDKH_P,.m7_uKyBfgPHZXPdeqCay:hover .r2ojZK26G8CSTu6Pn4Jj{opacity:1}.jqe5OoEDEAF8ZmMmVPq4{overflow-y:auto;border-radius:2px;padding:12px 60px 12px 20px}.odse2QHgVWTl5i20MKXg{max-height:400px}.r2ojZK26G8CSTu6Pn4Jj,.aKm5zEOfOz0C_VfDKH_P{position:absolute;top:15px;right:15px;transition:opacity .2s ease;opacity:0}`, "",{"version":3,"sources":["webpack://./components/SourceCode/SourceCode.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,UAAA,CACA,gBAAA,CAEA,oGAEE,SAAA,CAIJ,sBACE,eAAA,CACA,iBAAA,CACA,2BAAA,CAEA,sBACE,gBAAA,CAIJ,4CAEE,iBAAA,CACA,QAAA,CACA,UAAA,CACA,2BAAA,CACA,SAAA","sourcesContent":[".root {\n  position: relative;\n  width: 100%;\n  min-height: 200px;\n\n  &:hover .copyButton,\n  &:hover .copyIcon {\n    opacity: 1;\n  }\n}\n\n.scroller {\n  overflow-y: auto;\n  border-radius: 2px;\n  padding: 12px 60px 12px 20px;\n\n  &--maxHeight {\n    max-height: 400px;\n  }\n}\n\n.copyIcon,\n.copyButton {\n  position: absolute;\n  top: 15px;\n  right: 15px;\n  transition: opacity 0.2s ease;\n  opacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `m7_uKyBfgPHZXPdeqCay`,
	"copyButton": `aKm5zEOfOz0C_VfDKH_P`,
	"copyIcon": `r2ojZK26G8CSTu6Pn4Jj`,
	"scroller": `jqe5OoEDEAF8ZmMmVPq4`,
	"scroller--maxHeight": `odse2QHgVWTl5i20MKXg`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Br1zRwV8JxNdLrrI8zBl{position:relative}.X44HmDjJomI_HdJEhBgG{position:absolute;top:0;left:0;z-index:-1}.swIEvAnExSAZ0Lwruryp{position:relative;top:-8px}`, "",{"version":3,"sources":["webpack://./components/ScheduleBorderedAvatar/ScheduleBorderedAvatar.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAGF,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CAGF,sBACE,iBAAA,CACA,QAAA","sourcesContent":[".root {\n  position: relative;\n}\n\n.avatar {\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: -1;\n}\n\n.icon {\n  position: relative;\n  top: -8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Br1zRwV8JxNdLrrI8zBl`,
	"avatar": `X44HmDjJomI_HdJEhBgG`,
	"icon": `swIEvAnExSAZ0Lwruryp`
};
export default ___CSS_LOADER_EXPORT___;

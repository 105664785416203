// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LeOW8WOWaZtw2hQ2eKPN {
  margin: 50px auto;
  text-align: center;
  width: 400px;
}

.HZ_9DbWEwjHt_xVmUDUP {
  color: var(--warning-text-color);
}

.VoEV_Wif4SlLccG0iMOw {
  color: white;
  margin-right: 4px;
  padding-top: 6px;
}
`, "",{"version":3,"sources":["webpack://./components/PageErrorHandlingWrapper/PageErrorHandlingWrapper.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".not-found {\n  margin: 50px auto;\n  text-align: center;\n  width: 400px;\n}\n\n.error-code {\n  color: var(--warning-text-color);\n}\n\n.change-team-icon {\n  color: white;\n  margin-right: 4px;\n  padding-top: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"not-found": `LeOW8WOWaZtw2hQ2eKPN`,
	"error-code": `HZ_9DbWEwjHt_xVmUDUP`,
	"change-team-icon": `VoEV_Wif4SlLccG0iMOw`
};
export default ___CSS_LOADER_EXPORT___;

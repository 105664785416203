// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.O0CjvtDgyBaGE2LXDTT3{cursor:pointer;color:var(--primary-text-color);display:inline-flex;flex-direction:column;align-items:center;vertical-align:middle;justify-content:center;padding:4px}.XWqXBIsR3QgqdhPJr5Nv{height:32px;width:30px;cursor:pointer}.dR6xYPRdgB7Yb2abTnm8{height:24px;width:22px;cursor:pointer}`, "",{"version":3,"sources":["webpack://./components/HamburgerMenuIcon/HamburgerMenuIcon.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,+BAAA,CACA,mBAAA,CACA,qBAAA,CACA,kBAAA,CACA,qBAAA,CACA,sBAAA,CACA,WAAA,CAEA,sBACE,WAAA,CACA,UAAA,CACA,cAAA,CAGF,sBACE,WAAA,CACA,UAAA,CACA,cAAA","sourcesContent":[".hamburgerMenu {\n  cursor: pointer;\n  color: var(--primary-text-color);\n  display: inline-flex;\n  flex-direction: column;\n  align-items: center;\n  vertical-align: middle;\n  justify-content: center;\n  padding: 4px;\n\n  &--withBackground {\n    height: 32px;\n    width: 30px;\n    cursor: pointer;\n  }\n\n  &--small {\n    height: 24px;\n    width: 22px;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hamburgerMenu": `O0CjvtDgyBaGE2LXDTT3`,
	"hamburgerMenu--withBackground": `XWqXBIsR3QgqdhPJr5Nv`,
	"hamburgerMenu--small": `dR6xYPRdgB7Yb2abTnm8`
};
export default ___CSS_LOADER_EXPORT___;

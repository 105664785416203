// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FShnDknmcWoyOeMias_f {
  display: flex;
  flex-direction: column;
}

.nlQIyaFMwWqLQGpWk36s {
  cursor: pointer;
  position: relative;
  padding: 12px;
  opacity: 0.8;
}

.nlQIyaFMwWqLQGpWk36s:hover {
  background: var(--secondary-background);
  opacity: 1;
}

.K4n9yWL8X7z3VeGNdrND {
  cursor: default;
  opacity: 1;
}

.K4n9yWL8X7z3VeGNdrND::before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  top: 12px;
  bottom: 12px;
  width: 4px;
  background-image: linear-gradient(270deg, #f55f3e 0%, #f83 100%);
}
`, "",{"version":3,"sources":["webpack://./components/VerticalTabsBar/VerticalTabsBar.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,uCAAuC;EACvC,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,WAAW;EACX,kBAAkB;EAClB,OAAO;EACP,SAAS;EACT,YAAY;EACZ,UAAU;EACV,gEAAgE;AAClE","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n}\n\n.tab {\n  cursor: pointer;\n  position: relative;\n  padding: 12px;\n  opacity: 0.8;\n}\n\n.tab:hover {\n  background: var(--secondary-background);\n  opacity: 1;\n}\n\n.tab_active {\n  cursor: default;\n  opacity: 1;\n}\n\n.tab_active::before {\n  display: block;\n  content: '';\n  position: absolute;\n  left: 0;\n  top: 12px;\n  bottom: 12px;\n  width: 4px;\n  background-image: linear-gradient(270deg, #f55f3e 0%, #f83 100%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `FShnDknmcWoyOeMias_f`,
	"tab": `nlQIyaFMwWqLQGpWk36s`,
	"tab_active": `K4n9yWL8X7z3VeGNdrND`
};
export default ___CSS_LOADER_EXPORT___;

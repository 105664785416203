// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zOihEq3N_67gtE2ybvEA {
  display: inline-flex;
  align-items: center;
}

.zOihEq3N_67gtE2ybvEA .Sske5ztRv0RUKBVgtCCK {
  width: 300px;
}

.zOihEq3N_67gtE2ybvEA .Sske5ztRv0RUKBVgtCCK:focus {
  width: 500px;
}

.qial5G7gAiwnzR1MdCdu:not(:last-child) {
  margin-right: 20px;
}
`, "",{"version":3,"sources":["webpack://./components/UsersFilters/UsersFilters.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".root {\n  display: inline-flex;\n  align-items: center;\n}\n\n.root .search {\n  width: 300px;\n}\n\n.root .search:focus {\n  width: 500px;\n}\n\n.control:not(:last-child) {\n  margin-right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `zOihEq3N_67gtE2ybvEA`,
	"search": `Sske5ztRv0RUKBVgtCCK`,
	"control": `qial5G7gAiwnzR1MdCdu`
};
export default ___CSS_LOADER_EXPORT___;

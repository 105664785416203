// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.x6g9Zauh2qGxGOQ5ug70 {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.jCnBEi4pTEOV3v7DtmJk {
  margin-top: 100px;
}

.S5vNTBTqecVOxnEp2zNA {
  margin-top: 100px;
  display: flex;
  gap: 10px;
  margin-bottom: 100px;
  align-items: flex-start;
}

.OgLiv8IZQ4ESIOuYIkgW {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 120px;
  text-align: center;
}

.LlXmf4IAA3bl2_R0FSB7 {
  width: 60px;
  height: 60px;
  background: var(--secondary-background);
  border-radius: 50%;
  text-align: center;
  line-height: 55px;
}

.Za4cwmf07t3By3J4wLEQ {
  border: 2px solid #ffb375;
}

.CkGOLX4tR5hJLOZx0lJx {
  margin-top: 20px;
}

.CkGOLX4tR5hJLOZx0lJx svg {
  fill: #ccccdc;
}

.theme-dark .CkGOLX4tR5hJLOZx0lJx svg {
  fill-opacity: 0.15;
}

@media (min-width: 1540px) {
  .OgLiv8IZQ4ESIOuYIkgW {
    width: 170px;
  }
}
`, "",{"version":3,"sources":["webpack://./components/Tutorial/Tutorial.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,SAAS;EACT,oBAAoB;EACpB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,uCAAuC;EACvC,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".root {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n}\n\n.title {\n  margin-top: 100px;\n}\n\n.steps {\n  margin-top: 100px;\n  display: flex;\n  gap: 10px;\n  margin-bottom: 100px;\n  align-items: flex-start;\n}\n\n.step {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  align-items: center;\n  width: 120px;\n  text-align: center;\n}\n\n.icon {\n  width: 60px;\n  height: 60px;\n  background: var(--secondary-background);\n  border-radius: 50%;\n  text-align: center;\n  line-height: 55px;\n}\n\n.icon_active {\n  border: 2px solid #ffb375;\n}\n\n.arrow {\n  margin-top: 20px;\n}\n\n.arrow svg {\n  fill: #ccccdc;\n}\n\n:global(.theme-dark) .arrow svg {\n  fill-opacity: 0.15;\n}\n\n@media (min-width: 1540px) {\n  .step {\n    width: 170px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `x6g9Zauh2qGxGOQ5ug70`,
	"title": `jCnBEi4pTEOV3v7DtmJk`,
	"steps": `S5vNTBTqecVOxnEp2zNA`,
	"step": `OgLiv8IZQ4ESIOuYIkgW`,
	"icon": `LlXmf4IAA3bl2_R0FSB7`,
	"icon_active": `Za4cwmf07t3By3J4wLEQ`,
	"arrow": `CkGOLX4tR5hJLOZx0lJx`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NCLHEfyC3fjQQi2apPjG {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 2px;
  background: var(--secondary-background);
  padding: 2px 2px 2px 12px;
  flex-wrap: wrap;
  min-height: 36px;
}

.GepN87EsNykhVDNfaz31 > * {
  margin-right: 8px !important;
}

.NCLHEfyC3fjQQi2apPjG .ccsIk37pU_UClwOGWw4S {
  flex-shrink: 0;
  min-width: 100px;
}

.NCLHEfyC3fjQQi2apPjG .Qzlxen_5h6lzL1j8VjiX {
  max-width: 90%;
  padding: 4px;
}

.NCLHEfyC3fjQQi2apPjG .hzoDVTkgLYa7GYjPlkgK {
  flex-grow: 1;
  width: 400px;
}

.TGy1jcIA328tW7ZKIfjw {
  width: 400px;
}

.NCLHEfyC3fjQQi2apPjG .B828glpEce8Ji5kJSWoT {
  margin: 0 8px;
  width: auto !important;
}

.NCLHEfyC3fjQQi2apPjG .tY7crUB9rU5yyK8FkBKZ {
  margin-left: auto !important;
}
`, "",{"version":3,"sources":["webpack://./components/Policy/EscalationPolicy.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,kBAAkB;EAClB,uCAAuC;EACvC,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".root {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  border-radius: 2px;\n  background: var(--secondary-background);\n  padding: 2px 2px 2px 12px;\n  flex-wrap: wrap;\n  min-height: 36px;\n}\n\n.step > * {\n  margin-right: 8px !important;\n}\n\n.root .select {\n  flex-shrink: 0;\n  min-width: 100px;\n}\n\n.root .multiSelect {\n  max-width: 90%;\n  padding: 4px;\n}\n\n.root .users {\n  flex-grow: 1;\n  width: 400px;\n}\n\n.timeline {\n  width: 400px;\n}\n\n.root .control {\n  margin: 0 8px;\n  width: auto !important;\n}\n\n.root .delete {\n  margin-left: auto !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `NCLHEfyC3fjQQi2apPjG`,
	"step": `GepN87EsNykhVDNfaz31`,
	"select": `ccsIk37pU_UClwOGWw4S`,
	"multiSelect": `Qzlxen_5h6lzL1j8VjiX`,
	"users": `hzoDVTkgLYa7GYjPlkgK`,
	"timeline": `TGy1jcIA328tW7ZKIfjw`,
	"control": `B828glpEce8Ji5kJSWoT`,
	"delete": `tY7crUB9rU5yyK8FkBKZ`
};
export default ___CSS_LOADER_EXPORT___;
